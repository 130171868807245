import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import SidebarHeader from './SidebarHeader';
import SidebarBody from './SidebarBody';
import { connect, useSelector, useDispatch } from 'react-redux';
import './Sidebar.scss';

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const open = useSelector(state => state.userInterface.isSidebarOpen);
  
  return (
    <Col className={'sidebar m-0 p-0' + (open ? ' sidebar-open' : '')}>
      <Container className="p-0">
        <SidebarHeader/>
        <SidebarBody />
      </Container>
    </Col>
  );
};

const mapStateToProps = (state) => {
  const { userInterface } = state;
  return {
    open: userInterface.isSidebarOpen
  };
};

export default connect(mapStateToProps)(Sidebar);
