import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import MainApp from '../MainApp/MainApp';
import Login from '../Login/Login';
import Logout from '../Logout/Logout';
import useAuthenticationRouter from './useAuthenticationRouter';

const AuthenticationRouter = () => {
  const { isLogin, currentRole } = useAuthenticationRouter();

  if (isLogin) {
    if (currentRole === 'Foodbam Admin') {
      return (
        <Switch>
          <Route exact path='/logout' component={Logout} />
          <Route path='/' component={MainApp} />
        </Switch>
      );
    }    
  };
  
  return (
    <Switch>
      <Route exact path='/login' component={Login} />      
      <Route>
        <Redirect to='/login' />
      </Route>
    </Switch>
  );
};

export default AuthenticationRouter;