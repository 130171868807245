import { closeSidebar, toggleSidebar } from '../../reducers/userInterface/userInterfaceActions';
import { useRef, useState } from 'react';
import { changeTourMoral } from '../../reducers/modals/modalsActions';
import { isMobile } from "react-device-detect";

const useActions = (dispatch) => {
  const clickClose = () => {
    dispatch(closeSidebar());
  };

  
  return {
    clickClose,
  };
};

export default {
  useActions
};
