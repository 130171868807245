
import React, {Fragment, useState, useEffect} from 'react';
import { isMobile } from 'react-device-detect';
import { Container } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import useTemplate from '../useTemplate';
import TemplateMatchesRow from "./TemplateMatchesRow";
import TemplateMatchesHeader from "./TemplateMatchesHeader";

const TemplateMatches = (props) => {
    const history = useHistory();    
    const section = history.location.pathname.split('/');
    const tempId = section[section.length-2];
    const { matchList } = useTemplate.useMatchesApi(tempId);

    const [selectMatchHeader, setSelecteMatchHeader] = useState(false);
    const [selectedMatches, setSelectedMatches] = useState([]);
    const matchesOptions = {tempId, selectedMatches, setSelectedMatches, matchList, selectMatchHeader, setSelecteMatchHeader};

    const templateMatchesMap = matchList.map(t => 
        <TemplateMatchesRow  key={t.id} id={t.id} data={t} {...matchesOptions}/>
    );

    useEffect(() => {
        if(selectedMatches.length === 0){
            setSelecteMatchHeader(false);
            return;
        }
        
        if(matchList.length === selectedMatches.length){
            setSelecteMatchHeader(true);
        }else{
            setSelecteMatchHeader(false);
        }
    }, [selectedMatches]);

    return (
        <Fragment>
            <TemplateMatchesHeader {...matchesOptions}/>
            {templateMatchesMap}
        </Fragment>
    )
}

export default TemplateMatches;