import React from 'react';
import { Row, Col } from 'reactstrap';
import { MdArrowDropUp, MdClose, MdArrowDropDown } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import useSidebar from './useSidebar';
import ShowIf from '../ShowIf/ShowIf';

const SidebarHeader = () => {

  const dispatch = useDispatch();
  const { clickClose } = useSidebar.useActions(dispatch);

  let logoUrl = 'https://diningalliance.s3.amazonaws.com/organization/default.png';
  
  return (
    <Row className='sidebar-header m-0'>
      <Col xs={2} className="p-0 overflow-hidden">
        <img src={logoUrl} style={{width: '35px'}} />
      </Col>
      <Col xs={8} className="p-0 active-location">
        <span>Distributor Admin Portal</span>
      </Col>
      <Col xs={1}>        
        <MdClose color="#fff" onClick={clickClose} className="clickable" style={{color: '#ffffff! important', position: 'relative', top: '-26px', left: '16px'}} />
      </Col>
    </Row>
  );
};

export default SidebarHeader;
