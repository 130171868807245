import React, {Fragment, useState,useEffect} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Button, Card, Nav, NavItem, NavLink,  TabContent, TabPane  } from 'reactstrap';
import useModal from '../../../../../SharedComponents/Modals/useModal';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import TestRow from '../TemplateSettings/TestRow';
import TestInvalidRow from '../TemplateSettings/TestInvalidRow';
import classnames from 'classnames';
import { isMobile } from 'react-device-detect';
import '../Template.scss';
import '../../../../../config/constants.scss';
import { modalMenuClassName } from '../../../../../config/constants';
import AlertModal from '../../../../../SharedComponents/Modals/AlertModal/AlertModal';
import { useSelector, useDispatch } from "react-redux";
import { showModal } from "../../../../../reducers/modals/modalsActions";
import templateApi from '../../../../../api/templateApi';
import orderGuideApi from '../../../../../api/orderGuideApi';
import { useHistory } from 'react-router-dom';

const TemplateMatchTestModal = (props) => {
    const { id, menu, fullMenu, template, data, test, footerClass, title } = props;
    const modalprops = useModal(id, menu, fullMenu);
    const showTitle = title ? true : false;

    const dispatch = useDispatch();
    const history = useHistory();   

    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }
    const [testResult, setTestResult] = useState([]);
    const [validated, setValidated] = useState('');
    const [splited, setSplited] = useState('');
    const [blocked, setBlocked] = useState('');
    const [nonBlocked, setNonBlocked] = useState('');

    const cancel = () => {
        modalprops.toggle();
    };

    const approve = () => {
        if(!data.queue.merchants){ return; }
        if(data.queue.status){
            let message=<Row className='my-3'><Col className='text-center'>Order guide will be uploaded for</Col></Row>;
            let messageMerch='';            
            messageMerch = data.queue.merchants.map((m, i) => (<Row key={`qm-${m.id}-${i}`} className='p-1'><Col className='text-center'>{m.nickname}</Col></Row>));
            const newProps = {
                message: <Fragment>{message } {messageMerch}</Fragment>,
                btnLabel: 'Ok',
                btnSize: 'lg',
                color: 'green',
                onOk: () => processQueue()
            };
            dispatch(showModal(AlertModal, newProps));
        }
        modalprops.toggle();
    };

    const goBack = () => {
        history.push(`/templates/${template}/matches`);
    };

    const processQueue = () => {
        if(!data.queue){ return; }

        const params = {
            queue: '['+data.queue.id+']',
            templateId: template
        };
        
        orderGuideApi.uploadQueue(params).then((res) => {
            templateApi.discardTemplate(data.id).then((res) => {
                const newProps = {
                    message: 'Queued processed successfully',
                    btnLabel: 'Ok',
                    btnSize: 'lg',
                    color: 'green',
                    onOk: () => goBack()
                };
                dispatch(showModal(AlertModal, newProps));                
            })
            .catch(error => {});
        })
        .catch(error => {
            const newProps = {
                title: 'Error',
                message: 'There was an error while processing queue',
                btnLabel: 'Ok',
                btnSize: 'lg',
                color: 'green'
            };
            dispatch(showModal(AlertModal, newProps));
        });
    };

  useEffect(() => {
      if(test){
        setTestResult(test);
        setValidated(test.validatedItems.map((t,i) => <TestRow  key={`test_${i}`} data={t} /> ));
        setSplited(test.splitedItems.length===0 ?  <Card className='p-2'>No Items found</Card>: test.splitedItems.map((t,i) => <TestRow  key={`test_${i}`} data={t} /> ));
        setBlocked(test.blockedItems.length===0 ? <Card className='p-2'>No Items found</Card>:  test.blockedItems.map((t,i) => <TestInvalidRow  key={`test_${i}`} data={t} /> ));
        setNonBlocked(test.nonBlockedItems.length===0 ? <Card className='p-2'>No Items found</Card> : test.nonBlockedItems.map((t,i) => <TestInvalidRow  key={`test_${i}`} data={t} /> ));
      }    
  }, [test])

  return (
    <Modal {...modalprops} size='xl' modalClassName={modalMenuClassName}>
      <ShowIf show={showTitle}>
        <ModalHeader className='text-center'>{title}</ModalHeader>
      </ShowIf>
      <ModalBody className='m-0 p-0'>
        <div className='h-300px mh-300px overflow-auto px-4 py-2' style={{fontSize: '14px'}}>
            <Nav tabs>
                <NavItem>
                    <NavLink className={'clickable' + (activeTab === '1' ? ' active' : ' inactive')} onClick={() => { toggle('1'); }} >
                        Blocked Items ({!!testResult.blockedItems ?  testResult.blockedItems.length : 0})
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={'clickable' + (activeTab === '2' ? ' active' : ' inactive')} onClick={() => { toggle('2'); }} >
                        Non Blocked Items ({!!testResult.nonBlockedItems ? testResult.nonBlockedItems.length : 0})
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink  className={'clickable' + (activeTab === '3' ? ' active' : ' inactive')}  onClick={() => { toggle('3'); }} >
                        Splited Items ({!!testResult.splitedItems ? testResult.splitedItems.length : 0})
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink  className={'clickable' + (activeTab === '4' ? ' active' : ' inactive')}  onClick={() => { toggle('4'); }} >
                        Validated Items ({!!testResult.validatedItems ? testResult.validatedItems.length : 0 })
                    </NavLink>
                </NavItem>
            </Nav>
            
            <TabContent activeTab={activeTab} >
                <TabPane tabId="1">
                    <Row className='m-0 p-2 text-gray d-flex align-items-center' style={{fontSize: '14px', background: '#EEF3F6'}}>
                        <Col xs={1}> DIN </Col>
                        <Col xs={1}> Type</Col>
                        <Col xs={3}> Description</Col>
                        <Col xs={1}> Value</Col>
                        <Col xs={1}> Row</Col>
                    </Row>
                    {blocked}
                </TabPane>
            </TabContent>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="2">
                    <Row className='m-0 p-2 text-gray align-items-center' style={{fontSize: '14px', background: '#EEF3F6'}}>
                        <Col xs={1}> DIN </Col>
                        <Col xs={1}> Type</Col>
                        <Col xs={4}> Description</Col>
                        <Col xs={2}> Value</Col>
                        <Col xs={1}> Row</Col>
                    </Row>
                    {nonBlocked}
                </TabPane>
            </TabContent>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="3">
                    <Row className='m-0 p-2 text-gray align-items-center' style={{fontSize: '14px', background: '#EEF3F6'}}>
                        <Col xs={1}> DIN </Col>
                        <Col xs={2}> Name</Col>
                        <Col xs={1}> UOM</Col>
                        <Col xs={1}> Price</Col>
                        <Col xs={1}> Pack Size</Col>
                        <Col xs={1}>Outer Pack</Col>
                        <Col xs={1}>Inner Pack</Col>
                        <Col xs={1}>Pack Uom</Col>
                        <Col xs={1}> Unit Price</Col>
                        <Col xs={1}> Brand</Col>
                        <Col xs={1}> Category</Col>
                    </Row>
                    {splited}
                </TabPane>
            </TabContent>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="4">
                    <Row className='m-0 p-2 text-gray align-items-center' style={{fontSize: '14px', background: '#EEF3F6'}}>
                        <Col xs={1}> DIN </Col>
                        <Col xs={2}> Name</Col>
                        <Col xs={1}> UOM</Col>
                        <Col xs={1}> Price</Col>
                        <Col xs={1}> Pack Size</Col>
                        <Col xs={1}>Outer Pack</Col>
                        <Col xs={1}>Inner Pack</Col>
                        <Col xs={1}>Pack Uom</Col>
                        <Col xs={1}> Unit Price</Col>
                        <Col xs={1}> Brand</Col>
                        <Col xs={1}> Category</Col>
                    </Row>
                    {validated}
                </TabPane>
            </TabContent>            
        </div>
      </ModalBody>
      <ModalFooter className={footerClass}>
        <Row className='py-2 text-center w-100'>
            <Col className='d-flex justify-content-end'>
                <Button color='orange' className={isMobile ? ' btn-block' : ''} onClick={() => cancel()}>Cancel</Button>
            </Col>
            <Col className='d-flex justify-content-start'>
                <Button color='green' className={isMobile ? 'btn-block' : ''} onClick={() => approve()}>Approve</Button>
            </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

TemplateMatchTestModal.defaultProps = {
    centered: true,
    scrollable: true,
    footerClass: '',
    btnLabel: 'Ok!',
    btnSize: 'sm',
    color: 'green'
};

export default TemplateMatchTestModal;
