
import React, {Fragment, useState, useEffect} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Container } from 'reactstrap';
import useTemplate from '../useTemplate';
import TemplateRow from "./TemplateRow";
import TemplateHeader from "./TemplateHeader";
import InfiniteScroll from 'react-infinite-scroller';

const TemplateList = (props) => {    
    const apiResult = useTemplate.useApi(props);
    const [templateMap, setTemplateMap] = useState('');
    const { templateList, getTemplates, page, isLoading, loadMore } = apiResult;

    // const templateMap = templateList.map(t => 
    //     <TemplateRow  key={t.id} id={t.id} data={t} {...apiResult} />
    // );

    useEffect(() => {
        setTemplateMap(templateList.map(t => <TemplateRow  key={t.id} id={t.id} data={t} {...apiResult} />));
    }, [templateList])

    return (
        <Fragment>
            <TemplateHeader/>
            <InfiniteScroll
                initialLoad = {false}
                pageStart={page}
                loadMore={getTemplates}
                hasMore={loadMore && !isLoading}
                useWindow={true}
            >
                {templateMap}
            </InfiniteScroll>
        </Fragment>
    )
}

export default TemplateList;