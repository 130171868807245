import React, {useState} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, CustomInput } from 'reactstrap';
import useModal from '../../../../../SharedComponents/Modals/useModal';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { MdClose } from 'react-icons/md';
import { colors, emailTypeOptions } from "../../../../../config/constants";
import EmailTypeRow from './EmailTypeRow';
import useInput from "../../../../../SharedComponents/SharedEffects/useInput";

const RejectModal = (props) => {
  const { id, onOk, btnLabel, btnSize, footerClass, color, title, user, uploadForMerchants, distributor, assignedMerchants, lastUpload, isDsr } = props;
  const modalprops = useModal(id);
  const showTitle = title ? true : false;
  
  const { type1, type2 } = useInput.useRadioGroup(['type1', 'type2']);

  const onClickOk = () => {
    if (onOk) {
        if(type1.value){
            onOk(user, uploadForMerchants, distributor, assignedMerchants, lastUpload, emailTypeOptions.type1);      
        }else if(type2.value){            
            onOk(user, uploadForMerchants, distributor, assignedMerchants, lastUpload, emailTypeOptions.type2);
        }      
    };
    modalprops.toggle();
  };


  return (
    <Modal {...modalprops} backdrop='static' keyboard={false}>
      <ShowIf show={showTitle}>
        <ModalHeader className='text-center'>
            <Row className='m-0'>
                <Col className='col-11 d-flex justify-content-center px-1 clickable'>
                    {title}
                </Col>
                <Col className='w-fc d-flex justify-content-end px-1 clickable' onClick={() => {modalprops.toggle()}}>
                    <MdClose className='clickable' size={20} color={colors.lightBlueText} />
                </Col>
            </Row>
        </ModalHeader>        
      </ShowIf>
      <ModalBody className='text-center'>
            <Row className='m-0'>
                <Col className='col-4 p-1 text-gray text-right'>
                    Type of User: 
                </Col>
                <Col className='w-fc p-1 text-left'>
                    {isDsr ? 'Dsr' : 'Customer'}
                </Col>
            </Row>
            <Row className='m-0'>
                <Col className='col-4 p-1 text-gray text-right'>
                    Email of user trying to Upload:
                </Col>
                <Col className='w-fc p-1 text-left'>
                    {user.email}
                </Col>
            </Row>
            <Row className='m-0'>
                <Col className='col-4 p-1 text-gray text-right'>
                    Distributor:
                </Col>
                <Col className='w-fc p-1 text-left'>
                    {distributor.nickname}
                </Col>
            </Row>
            <Row className='m-0'>
                <Col className='col-4 p-1 text-gray text-right'>
                    Merchants:
                </Col>
                <Col className='w-fc p-1 text-left'>
                    {assignedMerchants.map((m,i) => <Row key={`assigned_merchant_${i}`}><Col> {m.nickname} </Col></Row> )}
                </Col>
            </Row>
            <Row className='m-0'>
                <Col className='col-4 p-1 text-gray text-right'>
                    Most recent upload:
                </Col>
                <Col className='w-fc p-1 text-left'>
                    {lastUpload ? lastUpload.create_time : ''}
                </Col>
            </Row>
            <Row className='m-0'>
                <Col className='col-4 p-1 text-gray text-right'>
                    Status:
                </Col>
                <Col className='w-fc p-1 text-left text-capitalize'>
                    {lastUpload ? lastUpload.upload_status : ''}
                </Col>
            </Row>
            <Row className='m-0'>
                <Col className='col-4 p-1 text-gray text-right'>
                    Template:
                </Col>
                <Col className='w-fc p-1 text-left text-capitalize'>
                    {lastUpload ? (lastUpload.template ? `${lastUpload.template.name} - ${lastUpload.template.status}` : '' ) : ''}
                </Col>
            </Row>
            <Row className='m-0'>
                <Col className='col-4 p-1 text-gray text-right'>
                    Email:
                </Col>
                <Col className='col p-1 text-left text-capitalize'>
                    <Row className='m-0'>
                        <EmailTypeRow {...type1} id='type1' label='Type 1' name='email'/>
                        <EmailTypeRow {...type2} id='type2' label='Type 2' name='email'/>
                    </Row>
                </Col>
            </Row>
      </ModalBody>
      <ModalFooter className={footerClass}>
        <div className="text-center w-100">
          <Button color={color} size={btnSize} onClick={onClickOk}>{btnLabel}</Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

RejectModal.defaultProps = {
  footerClass: '',
  btnLabel: 'Ok!',
  btnSize: 'sm',
  color: 'blue'
};

export default RejectModal;
