import React, { Fragment, useState, useEffect } from 'react';
import { Col } from 'reactstrap';
import Select from 'react-select';

const MatchHeadersSelect = (props) => {
  const { id, placeholder, options, set, init } = props;

  const [option, setOption] = useState([]);

  const retrieveValidOptions = (opts) => {
    if(opts == null){
      return options[0];
    }
    const nOpt = opts.filter(opt => (opt.value !== '' ));
    if(nOpt.length===0){
      return options[0];
    }
    return nOpt;
  };

  const handleOption = (e) => {
      const newOpts = retrieveValidOptions(e);
      set(newOpts);
      setOption(newOpts)
  };

  useEffect(() => {
    if(!init){return;}
    const initOptions = [];
    options.map(opt => {
      init.match.forEach((m, i) => {
        if(opt.value === m){
          initOptions.push(opt);
        }
      });      
    });
    if(initOptions.length===0){
      setOption(options[0]);
      return;
    }
    setOption(initOptions);
  }, []);

  return (
    <Fragment>
      <Select className='fb-select' classNamePrefix='select'
            id={id}
            isMulti 
            placeholder={placeholder} 
            options={options} 
            onChange={handleOption}
            value={option}
        />
    </Fragment>
  );
};

export default MatchHeadersSelect;