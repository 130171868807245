import axios from 'axios';

const getAllTemplates = (params) => {
  return axios.get('/distributor-order-guide/search',{params});
};

const getTemplate = (id) => {
  return axios.get(`/distributor-order-guide/${id}`);
};

const saveInitialSettings = (id,params) => {
  return axios.patch(`/distributor-order-guide/${id}`,params);
};

const getFileHeaders = (id, params) => {
  return axios.post(`/distributor-order-guide/${id}/retrieve-headers`, params);
};

const getOgHeaders = (id) => {
  return axios.get(`/distributor-order-guide/retrieve-fields`);
};

const getOgTemplateHeaders = (id) => {
  return axios.get(`/distributor-order-guide/${id}/retrieve-og-fields`);
};

const matchHeaders = (id, params) => {
  return axios.post(`/distributor-order-guide/${id}/match-fields`, params);
};

const test = (id, params) => {
  return axios.post(`/distributor-order-guide/${id}/test`, params);
};

const approve = (id) => {
  return axios.post(`/distributor-order-guide/${id}/approve`);
};

const deleteTemplate = (id) => {
  return axios.delete(`/distributor-order-guide/${id}`);
};

const discardTemplate = (id) => {
  return axios.post(`/distributor-order-guide/${id}/inactive`);
};

const checkTemplateMatches = (id) => {
  return axios.post(`distributor-order-guide/${id}/check-template-matches`);
};

const rejectTemplate = (id, to, body, emailType, file) => {

  const formData = new FormData();
  formData.append('to', JSON.stringify(to));
  formData.append('body', JSON.stringify(body));
  formData.append('reject_type', JSON.stringify(emailType));
  formData.append('file', file);

  return axios.post(`/distributor-order-guide/${id}/reject`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
};

const userReport = () => {  
  return axios.get(`/distributor-order-guide/user-report`);
};


export default {
  getAllTemplates,
  getTemplate,
  saveInitialSettings,
  getFileHeaders,
  getOgHeaders,
  getOgTemplateHeaders,
  matchHeaders,
  test,
  approve,
  deleteTemplate,
  discardTemplate,
  checkTemplateMatches,
  rejectTemplate,
  userReport
};