import React from 'react';
import { Card, Row, Col, Button } from "reactstrap";
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import Moment from 'react-moment';
import { isMobile } from 'react-device-detect';
import '../Template.scss';
import numeral from 'numeral';

const TestRow = (props) => {
    const { data, style } = props;
    
    return (
       <Card className='p-2'>
           <ShowIf show={!isMobile}>
                <Row style={{fontSize: style}}>
                    <Col xs={1}> {data.din} </Col>
                    <Col xs={2} className='px-0 text-capitalize text-truncate'> {data.item_name}</Col>
                    <Col xs={1}> {data.unit_measure}</Col>
                    <Col xs={1}> {numeral(data.price).format('$0,0.00')}</Col>
                    <Col xs={1}> {data.unit_quantity + '/' + data.unit_size + ' ' + data.pack_size_unit}</Col>

                    <Col xs={1}>{data.unit_quantity}</Col>
                    <Col xs={1}>{data.unit_size}</Col>
                    <Col xs={1}>{data.pack_size_unit}</Col>

                    <Col xs={1}> {numeral(data.unit_price).format('$0,0.00')}</Col>
                    <Col xs={1} className='text-capitalize text-truncate'> {data.brand}</Col>
                    <Col xs={1} className='text-capitalize text-truncate'> {data.category}</Col>
                </Row>
            </ShowIf>
            
       </Card>
    )

}

TestRow.defaultProps = {
    style: '14px',
};

export default TestRow;