import React, {useState} from 'react';
import { Card, Row, Col, Button, Tooltip } from "reactstrap";
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import Moment from 'react-moment';
import { MdCloudDownload, MdEdit, MdDelete } from 'react-icons/md';
import { IoIosEye, IoMdSettings } from 'react-icons/io';
import { FaEquals } from 'react-icons/fa';
import { colors } from "../../../../../config/constants";
import { isMobile } from 'react-device-detect';
import useTemplate from '../useTemplate';
import '../Template.scss';
import QuestionModal from '../../../../../SharedComponents/Modals/QuestionModal/QuestionModal';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../../../reducers/modals/modalsActions';

const TemplateRow = (props) => {
    const { data } = props;
    const dispatch = useDispatch();
    const { setHover, hover, viewTemplate, openSettings, downloadTemplate, deleteTemplate, matchingTemplates } = useTemplate.useRow(props);

    const [tooltipOpenSet, setTooltipOpenSet] = useState(false);
    const toggleSet = () => setTooltipOpenSet(!tooltipOpenSet);

    const [tooltipOpenView, setTooltipOpenView] = useState(false);
    const toggleView = () => setTooltipOpenView(!tooltipOpenView);

    const [tooltipOpenMatch, setTooltipOpenMatch] = useState(false);
    const toggleMatch = () => setTooltipOpenMatch(!tooltipOpenMatch);

    const [tooltipOpenDown, setTooltipOpenDown] = useState(false);
    const toggleDown = () => setTooltipOpenDown(!tooltipOpenDown);

    const [tooltipOpenDel, setTooltipOpenDel] = useState(false);
    const toggleDel = () => setTooltipOpenDel(!tooltipOpenDel);

    const deleteFromList = (id) => {
        const questionProps = {
            message: `Are you sure you want to delete this parser?`,
            menu: isMobile ? true : false,
            leftButton: {
                title: 'Delete',
                color: 'orange',
                onClick: () => deleteTemplate(id)
            },
            rightButton: {
                title: 'Cancel',
                color: 'green',
            }
        };
        dispatch(showModal(QuestionModal, questionProps));
    }

    return (
       <Card>
           <ShowIf show={!isMobile}>
                <Row className='py-3 px-3 d-flex align-items-center ' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                    <Col xs={2} className='text-capitalize'> {data.name} </Col>
                    <Col xs={3} className='text-capitalize'> {!!data.distributor ? data.distributor.nickname : ''} </Col>
                    <Col xs={1} className='text-capitalize'> {!data.create_time ? '' : <Moment format="MM/DD/YYYY">{data.create_time}</Moment>} </Col> 
                    <Col xs={1} className=''> {(data.days_to_expire) ? data.days_to_expire + ` days` : ``} </Col>
                    <Col xs={1} className='text-capitalize'> {data.status}</Col>                    
                    <Col xs={2} className='text-capitalize'>
                        <Row className='d-flex align-items-center text-gray' style={{fontSize:'0.9em'}}>
                            <Col className='text-center clickable'>                                
                                { 
                                    data.status !== 'rejected' ? 
                                    (data.status !== 'approved' ? 
                                        <IoMdSettings color={colors.lightBlueText} size={20} onClick={() => openSettings(data)} id='tooltip-set'/> 
                                        : <MdEdit color={colors.lightBlueText} size={20} onClick={() => openSettings(data)} id='tooltip-set'/> 
                                    ): ''
                                }
                                <Tooltip
                                    placement='bottom'
                                    isOpen={tooltipOpenSet}
                                    target='tooltip-set'
                                    toggle={toggleSet}
                                >
                                    {data.status !== 'approved' ? `Set` : `Edit`}
                                </Tooltip>
                            </Col>
                            <Col className='text-center clickable'>
                                <IoIosEye color={colors.lightBlueText} size={20} onClick={() => viewTemplate(data.id)} id='tooltip-view'/>
                                <Tooltip
                                    placement='bottom'
                                    isOpen={tooltipOpenView}
                                    target='tooltip-view'
                                    toggle={toggleView}
                                >
                                    View
                                </Tooltip>
                            </Col>
                            <Col className='text-center clickable' >
                                <MdCloudDownload color={colors.lightBlueText} size={20} onClick={() => downloadTemplate(data.template_file)} id='tooltip-down'/>
                                <Tooltip
                                    placement='bottom'
                                    isOpen={tooltipOpenDown}
                                    target='tooltip-down'
                                    toggle={toggleDown}
                                >
                                    Download
                                </Tooltip>
                            </Col>
                            <Col className='text-center clickable' >
                                <MdDelete color={colors.lightBlueText} size={20} onClick={() => deleteFromList(data.id)} id='tooltip-del'/>
                                <Tooltip
                                    placement='bottom'
                                    isOpen={tooltipOpenDel}
                                    target='tooltip-del'
                                    toggle={toggleDel}
                                >
                                    Delete
                                </Tooltip>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ShowIf>
            <ShowIf show={isMobile}>
                <Col className='pb-4'>
                    <Row>
                        <Col className='py-2'> {data.name} </Col>
                    </Row>
                    <Row>
                        <Col className='col-6'>
                            <Row>
                                <Col className='py-1 text-gray text-capitalize'> Distributor </Col>
                            </Row>
                            <Row>
                                <Col className='py-1 text-gray text-capitalize'> Date </Col>
                            </Row>
                            <Row>
                                <Col className='py-1 text-gray text-capitalize'> Expiration (days) </Col>
                            </Row>
                            <Row>
                                <Col className='py-1 text-gray text-capitalize'> Status </Col>
                            </Row>
                        </Col>
                        <Col className='col-6 py-2 text-right'>
                            <Row>
                                <Col className='py-1 text-capitalize'> {!!data.distributor ? data.distributor.nickname : ''} </Col>
                            </Row>
                            <Row>
                                <Col className='py-1 text-capitalize'> {!data.create_time ? '' : <Moment format="MM/DD/YYYY">{data.create_time}</Moment>} </Col>
                            </Row>
                            <Row>
                                <Col className='py-1 text-capitalize'> {data.days_to_expire} </Col>
                            </Row>
                            <Row>
                                <Col className='py-1 text-capitalize'> {data.status} </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>                        
                        <Col>
                            <Button color='white' className='og-btn-outline' onClick={() => openSettings(data)} style={{width:'100%'}}>
                                { data.status !== 'approved' ? <IoMdSettings color={colors.lightBlueText} size={20} /> : <MdEdit color={colors.lightBlueText} size={20} /> }
                            </Button>
                        </Col>
                        <Col>
                            <Button color='white' className='og-btn-outline' onClick={() => viewTemplate(data.id)} style={{width:'100%'}}>
                                <IoIosEye color={colors.lightBlueText} size={20} />
                            </Button>
                        </Col>
                        <Col>
                            <Button color='white' className='og-btn-outline' onClick={() => downloadTemplate(data.template_file)} style={{width:'100%'}}>
                                <MdCloudDownload color={colors.lightBlueText} size={20} />
                            </Button>
                        </Col>
                        <Col>
                            <Button color='white' className='og-btn-outline' onClick={() => deleteFromList(data.id)} style={{width:'100%'}}>
                                <MdDelete color={colors.lightBlueText} size={20} />
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </ShowIf>
       </Card>
    )

}

export default TemplateRow;