import { createStore } from 'redux';
import { combineReducers } from 'redux';
import userInterface from './userInterface/userInterfaceReducer';
import user from './user/userReducer';
import modals from './modals/modalsReducer';
import header from './header/headerReducer';

const reducer = combineReducers({
  userInterface, user, modals, header,  
});

export default createStore(reducer);