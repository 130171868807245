
import React, {useState, useEffect} from 'react';
import { isMobile } from 'react-device-detect';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { Row, Col, CustomInput } from 'reactstrap';
import Header from '../../../../../SharedComponents/Header/Header';

const TemplateMatchesHeader = (props) => {
    const { selectedMatches, setSelectedMatches, matchList, selectMatchHeader, setSelecteMatchHeader } = props;
    const [countHeader, setCountHeader] = useState(selectedMatches.length > 0 ? `${selectedMatches.length} Selected` : ``);
    

    const selectOption = () => {
        if(selectMatchHeader){
            setSelectedMatches([]);
        }else{
            setSelectedMatches(matchList);
        }
        setSelecteMatchHeader(!selectMatchHeader);
    };

    useEffect(() => {        
        const ncount = selectedMatches.length > 0 ? `${selectedMatches.length} Selected` : ``;
        setCountHeader(ncount);
    }, [selectedMatches]);


    return (
        <Header showMenu={true} showLogo={isMobile ? false : true} showBack={false} title='Order Guide Templates' showCart={false} >
            <ShowIf show={!isMobile}>
                <Row className='header h-inherit py-3 align-items-center m-0 text-gray'>
                    {/* <Col xs={1} className='text-center'>
                        <CustomInput type='checkbox' id={`template_match_input`} checked={selectMatchHeader} onChange={() => selectOption()} label={''} />
                        {countHeader}
                    </Col> */}
                    <Col xs={2}> Template Name</Col>
                    <Col xs={2}> Distributor </Col>
                    <Col xs={2}> Distributor Email </Col>
                    <Col xs={1}> Date </Col>
                    <Col xs={1}> Expires After </Col>
                    <Col xs={1}> Status </Col>                    
                    <Col xs={2}> Actions </Col>
                </Row>
            </ShowIf>            
        </Header>
    )
}

export default TemplateMatchesHeader;