
import React from 'react';
import { isMobile } from 'react-device-detect';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { Row, Col } from 'reactstrap';
import Header from '../../../../../SharedComponents/Header/Header';

const TemplateHeaderSettings = (props) => {  
    const {step} = props


    return (
        <Header showMenu={true} showLogo={isMobile ? false : true} showBack={false} title='Template Settings' showCart={false} >
            <ShowIf show={step==1}>
                <Row className='header h-inherit py-3 d-flex align-items-center text-main m-0'>
                    <Col className='d-flex justify-content-center'>File Settings</Col>
                </Row>
            </ShowIf>
            <ShowIf show={step==2}>
                <Row className='header h-inherit py-3 d-flex align-items-center text-main m-0'>
                    <Col className='d-flex justify-content-center'>Matching Headers</Col>
                </Row>
            </ShowIf>
            <ShowIf show={step==3}>
                <Row className='header h-inherit py-3 d-flex align-items-center text-main m-0'>
                    <Col className='d-flex justify-content-center'>Basic Info</Col>
                </Row>
            </ShowIf>
            <ShowIf show={step==4}>
                <Row className='header h-inherit py-3 d-flex align-items-center text-main m-0'>
                    <Col className='d-flex justify-content-center'>Review</Col>
                </Row>
            </ShowIf>
            <ShowIf show={step==5}>
                <Row className='header h-inherit py-3 d-flex align-items-center text-main m-0'>
                    <Col className='d-flex justify-content-center'>Test Result</Col>
                </Row>
            </ShowIf>
        </Header>
    )
}

export default TemplateHeaderSettings;