import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearUserData } from "../../reducers/user/userAction";

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();

  localStorage.clear();
  dispatch(clearUserData());


  history.push('/login');

};