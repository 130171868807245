import React from 'react';
import './App.scss';
import AuthenticationRouter from '../AuthenticationRouter/AuthenticationRouter';
import MainApp from '../MainApp/MainApp';
import Modals from '../../SharedComponents/Modals/Modals';
import Spinner from '../../SharedComponents/Spinner/Spinner';
import useApi from '../../SharedComponents/SharedEffects/useApi';
import setDefaults from '../../config/api';

function App() {  
  // useApi();
  setDefaults();
  
  return (
    <div className="App">
      <AuthenticationRouter />
      <Modals />
      <Spinner />
    </div>
  );
}

export default App;