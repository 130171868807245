import React, {useState, useEffect} from 'react';
import { Card, Row, Col, Button, CustomInput } from "reactstrap";
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import Moment from 'react-moment';
import { MdCloudDownload, MdEdit, MdDelete } from 'react-icons/md';
import { IoIosEye, IoMdSettings } from 'react-icons/io';
import { colors } from "../../../../../config/constants";
import { isMobile } from 'react-device-detect';
import useTemplate from '../useTemplate';
import '../Template.scss';
import QuestionModal from '../../../../../SharedComponents/Modals/QuestionModal/QuestionModal';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../../../reducers/modals/modalsActions';

const TemplateMatchesRow = (props) => {
    const { data, selectedMatches, setSelectedMatches, tempId } = props;
    const dispatch = useDispatch();
    const { testTemplate } = useTemplate.useMatchesRow(props);
    const [selectMatch, setSelectMatch] = useState(false);
    

    const selectOption = () => {
        let newMatches=[];
        newMatches = (selectMatch) ? selectedMatches.filter(m => m.id !== data.id) : selectedMatches.concat(data);
        setSelectedMatches(newMatches);
        setSelectMatch(!selectMatch);        
    };

    useEffect(() => {        
        if (selectedMatches.filter(m => m.id === data.id).length === 1){
            setSelectMatch(true);
        }else{
            setSelectMatch(false);
        }
    }, [selectedMatches]);

    return (
       <Card>
           <ShowIf show={!isMobile}>
                <Row className='py-3 px-3 d-flex align-items-center '>
                    {/* <Col xs={1} className='text-center'> 
                        <CustomInput type='checkbox' id={data.id} checked={selectMatch} onChange={() => selectOption()} label={''} />
                    </Col> */}
                    <Col xs={2} className='text-capitalize'>                        
                        {data.name} 
                    </Col>
                    <Col xs={2} className='text-capitalize'> {!!data.distributor ? data.distributor.nickname : ''} </Col>
                    <Col xs={2} > {!!data.upload_user ? data.upload_user.email : ''} </Col>
                    <Col xs={1} className='text-capitalize'> {!data.create_time ? '' : <Moment format="MM/DD/YYYY">{data.create_time}</Moment>} </Col> 
                    <Col xs={1} className=''> {(data.days_to_expire) ? data.days_to_expire + ` days` : ``} </Col>
                    <Col xs={1} className='text-capitalize'> {data.status}</Col>                    
                    <Col xs={2} className='text-capitalize'>
                        <Button color='green' onClick={() => testTemplate(data.id)}>
                            Test
                        </Button>
                    </Col>
                </Row>
            </ShowIf>
            <ShowIf show={isMobile}>
                <Col className='pb-4'>
                    <Row>
                        <Col className='py-2'> {data.name} </Col>
                    </Row>
                    <Row>
                        <Col className='col-6'>
                            <Row>
                                <Col className='py-1 text-gray text-capitalize'> Distributor </Col>
                            </Row>
                            <Row>
                                <Col className='py-1 text-gray text-capitalize'> Date </Col>
                            </Row>
                            <Row>
                                <Col className='py-1 text-gray text-capitalize'> Expiration (days) </Col>
                            </Row>
                            <Row>
                                <Col className='py-1 text-gray text-capitalize'> Status </Col>
                            </Row>
                        </Col>
                        <Col className='col-6 py-2 text-right'>
                            <Row>
                                <Col className='py-1 text-capitalize'> {!!data.distributor ? data.distributor.nickname : ''} </Col>
                            </Row>
                            <Row>
                                <Col className='py-1 text-capitalize'> {!data.create_time ? '' : <Moment format="MM/DD/YYYY">{data.create_time}</Moment>} </Col>
                            </Row>
                            <Row>
                                <Col className='py-1 text-capitalize'> {data.days_to_expire} </Col>
                            </Row>
                            <Row>
                                <Col className='py-1 text-capitalize'> {data.status} </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>                        
                        <Col>
                            <Button color='green' onClick={() => testTemplate(data.id)} style={{width:'100%'}}>
                                Test
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </ShowIf>
       </Card>
    )

}

export default TemplateMatchesRow;