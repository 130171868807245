import axios from 'axios';

const uploadQueue = (params) => {
  return axios.post('order-guide/upload-queue', params);
}

const lastUpload = (userId, distributorId) => {
  return axios.get(`order-guide/${userId}/last-upload/${distributorId}`);
}

export default {
    uploadQueue,
    lastUpload,
}