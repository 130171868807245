import React, {Fragment, useState, useEffect} from 'react';
import { Container, Card, Row, Col, Button } from "reactstrap";
import { useHistory } from 'react-router-dom';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import Moment from 'react-moment';
import { colors } from "../../../../../config/constants";
import { isMobile } from 'react-device-detect';
import useTemplate from '../useTemplate';
import '../Template.scss';
import TemplateHeaderDetail from "./TemplateHeaderDetail";
import { MdCloudDownload } from 'react-icons/md';

const TemplateDetail = (props) => {
    
    const history = useHistory();
    const section = history.location.pathname.split('/');
    const { data } = useTemplate.useDetailApi(section[section.length-2]);
    const [detail, setDetail] = useState('');
    const [match, setMatch] = useState('');
    
    const fullUser =  !!detail.upload_user ? (detail.upload_user.first_name ? detail.upload_user.first_name : '') + ' ' + (detail.upload_user.last_name ? detail.upload_user.last_name: '') + ' ('+ detail.upload_user.email + ')': '';
    
    const downloadTemplate = (fileUrl) => {
        const excelElement = document.createElement('a');
        excelElement.href = fileUrl;
        excelElement.click();
    }

    useEffect(() => {
        if(!!data){
            setDetail(data);
            if(!!data.parsed_fields){
                const file_fields = data.parsed_fields.split("*");
                const og_fields = data.og_fields.split("*");    
                const nheaders = file_fields.map((field, i) => 
                    <Card  key={`field_${i}`}>
                        <Row className='' >
                            <Col className='py-1 text-gray text-right'>{field}</Col>
                            <Col className='py-1 text-main  text-left' style={{fontSize:'0.9em'}}> {!!og_fields[i] ? og_fields[i] : '-'}</Col>
                        </Row>
                    </Card>
                );
                setMatch(nheaders);
            }
        }        
    }, [data]);

    return (
        <Fragment>
            <TemplateHeaderDetail id={detail.id}/>
            <ShowIf show={!isMobile}>
                <Row className='pt-4'>
                    <Col xs={3} className='py-1 text-right text-gray text-capitalize'> Name </Col>
                    <Col xs={8} className='py-1 text-left text-main text-capitalize'> {detail.name} </Col>
                </Row>

                <Row>
                    <Col xs={3} className='py-1 text-right text-gray text-capitalize'> Distributor </Col>
                    <Col xs={8} className='py-1 text-left text-main text-capitalize'> {!!detail.distributor ? detail.distributor.nickname : ''} </Col>
                </Row>

                <Row>
                    <Col xs={3} className='py-1 text-right text-gray text-capitalize'> File </Col>
                    <Col xs={8} className='py-1 text-left text-main'> 
                        <Button color='green' className={isMobile ? 'btn-block' : ''} onClick={() => downloadTemplate(detail.template_file)}>
                            <MdCloudDownload color='white' size={20} /> Download
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col xs={3} className='py-1 text-right text-gray text-capitalize'> Date </Col>
                    <Col xs={8} className='py-1 text-left text-main text-capitalize'> {!detail.create_time ? '' : <Moment format="MM/DD/YYYY">{detail.create_time}</Moment>} </Col>
                </Row>

                <Row>
                    <Col xs={3} className='py-1 text-right text-gray text-capitalize'> Expiration </Col>
                    <Col xs={8} className='py-1 text-left text-main'> {detail.days_to_expire} days</Col>
                </Row>

                <Row>
                    <Col xs={3} className='py-1 text-right text-gray text-capitalize'> Status </Col>
                    <Col xs={8} className='py-1 text-left text-main text-capitalize'> {detail.status} </Col>
                </Row>

                <Row>
                    <Col xs={3} className='py-1 text-right text-gray text-capitalize'> Rejection Type </Col>
                    <Col xs={8} className='py-1 text-left text-main text-capitalize'> {detail.reject_type} </Col>
                </Row>

                <Row>
                    <Col xs={3} className='py-1 text-right text-gray text-capitalize'> User </Col>
                    <Col xs={8} className='py-1 text-left text-main'>
                        {fullUser}
                    </Col>
                </Row>

                <Row>
                    <Col xs={3} className='py-1 text-right text-gray text-capitalize'> Merchants </Col>
                    <Col xs={8} className='py-1 text-left text-main'> 
                        {!!detail.queue ? 
                            detail.queue.merchants.map((m, j) =>  <Row  key={`queue_${j}`}> <Col> {m.nickname} </Col> </Row> )
                        : ''}
                    </Col>
                </Row>                

                <Row>
                    <Col xs={3} className='py-1 text-right text-gray text-capitalize'> Match Headers </Col>
                    <Col xs={8} className='py-1 text-left text-main'>  </Col>
                </Row>

                <Row>
                    <Col xs={3} className='py-1 text-right text-gray text-capitalize'></Col>
                    <Col xs={4} className='py-1 text-left'>
                        {match}
                    </Col>                    
                </Row>
            </ShowIf>

            <ShowIf show={isMobile}>
                <Col className='pb-4'>
                    <Row className='pt-2'>
                        <Col className='text-left text-gray text-capitalize'> Name </Col>
                        <Col className='text-right text-capitalize'> {detail.name} </Col>
                    </Row>
                    <Row className='py-1'>
                        <Col className='text-left text-gray text-capitalize'> Distributor </Col>
                        <Col className='text-right text-capitalize'> {!!detail.distributor ? detail.distributor.nickname : ''}  </Col>
                    </Row>
                    <Row className='py-1'>
                        <Col className='text-left text-gray text-capitalize'> File </Col>
                        <Col className='text-right text-capitalize'> 
                            <Button color='white' className='og-btn-outline' onClick={() => downloadTemplate(detail.template_file)} >
                                <MdCloudDownload color={colors.lightBlueText} size={20}  /> Download
                            </Button>
                        </Col>
                    </Row>
                    <Row className='py-1'>
                        <Col className='text-left text-gray text-capitalize'> Date </Col>
                        <Col className='text-right text-capitalize'> {!detail.create_time ? '' : <Moment format="MM/DD/YYYY">{detail.create_time}</Moment>} </Col>
                    </Row>
                    <Row className='py-1'>
                        <Col className='text-left text-gray text-capitalize'> Expiration </Col>
                        <Col className='text-right text-capitalize'> {detail.days_to_expire} days </Col>
                    </Row>
                    <Row className='py-1'>
                        <Col className='text-left text-gray text-capitalize'> Status </Col>
                        <Col className='text-right text-capitalize'> {detail.status} </Col>
                    </Row>
                    <Row className='py-1'>
                        <Col className='text-left text-gray text-capitalize'> User </Col>
                        <Col className='text-right text-capitalize'> {fullUser} </Col>
                    </Row>
                    <Row className='py-1'>
                        <Col className='text-left text-gray text-capitalize'> Merchants </Col>
                        <Col className='text-right text-capitalize'>
                            {!!detail.queue ? 
                                detail.queue.merchants.map((m, j) => 
                                    <Row  key={`queue_${j}`}>
                                        <Col> {m.nickname} </Col>
                                    </Row>
                                )
                            : ''}
                        </Col>
                    </Row>
                    <Row className='pt-2'>
                        <Col className='text-center text-gray text-capitalize'> Matching Headers </Col>
                    </Row>
                    {match}
                </Col> 
            </ShowIf>
        </Fragment>
       
    )

}

export default TemplateDetail;