import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Col } from 'reactstrap';
import Template from './Views/Template/Template';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';

const MainAppRouter = (props) => {
  const { isSidebarOpen } = props;
  
  return (
    <Col className={'p-0 main-app-content' + (isSidebarOpen ? ' sidebar-is-open' : '')}>
      <Switch>        
        <Route path='/templates' component={Template} />
        <Route>
          <Redirect to={'/templates'} />
        </Route>
      </Switch>
    </Col>
  );
};

export default MainAppRouter;
