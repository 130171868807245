import { useState } from "react";
import validate from 'validate.js';
import useInput from "../../SharedComponents/SharedEffects/useInput";
import authenticateApi from "../../api/authenticateApi";
import { useDispatch, useSelector } from "react-redux";
import { setUserData, setUserActiveLocation } from "../../reducers/user/userAction";
import { localStorageConst, placeOrderViews } from '../../config/constants';

const useLogin = () => {
  const dispatch = useDispatch();
  const doLogin = (data) => {
    if (!data.authKey) {
      data.authKey = localStorage.getItem(localStorageConst.keys.authKey);
    }
    localStorage.setItem(localStorageConst.keys.userName, data.firstName +' '+ data.lastName);
    localStorage.setItem(localStorageConst.keys.authKey, data.authKey);
    localStorage.setItem(localStorageConst.keys.currentRole, data.currentRole);
    dispatch(setUserData(data));
  };

  return { doLogin };
}

const useForm = () => {
  const [error, setError] = useState({});
  const { set : setEmail, value: email } = useInput.useText();
  const { set : setPassword, value: password } = useInput.useText();
  const { doLogin } = useLogin();

  const onSubmit = (e) => {
    e.preventDefault();
    const form = {
      email: email,
      password: password
    };
    const constrains = {
      email: {
        presence: {
          allowEmpty: false
        },
        email: true
      },
      password: {
        presence: {
          allowEmpty: false
        }
      }
    };
    const result = validate(form, constrains);
    setError(result ? result : {});
    if (!result) {
      authenticateApi.authenticate(email,password).then(({data}) => {
        doLogin(data.data);
      }).catch(err => {
        console.error(err);
        const status = err.code;
        if (status === 400) {
          setError({error: err.data.message});
        } else {
          setError({error: 'Something went wrong.'});
        }
      });
    }
  };

  return { error, onSubmit, setEmail, setPassword, password, email }
};

export default {
  useForm, useLogin
};
