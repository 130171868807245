import React, { useState } from 'react';
import { CustomInput, Row, Col } from 'reactstrap';

const EmailTypeRow = (props) => {
  const {
    label, name, id,
    value, set
  } = props;

  return (
    <Col className='col'>
        <Row>
            <Col className='pr-0'>
                <CustomInput id={id} type='radio' checked={value} onChange={set} name={name}/>
            </Col>        
            <Col className='pl-0'>
                <Row className='font-weight-bold'><label className='m-0' htmlFor={id}>{label}</label></Row>
            </Col>
        </Row>
    </Col>
  );
};

export default EmailTypeRow;