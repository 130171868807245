import axios from "axios";

const authenticate = (email,password) => {    
  return axios.post('/site/authenticate', {
          email: email,
          password: password,
          secret: "dpWebApp"
  });
};

export default {
  authenticate
};