export const setUserData = (data) => {
  return {
    type: 'SET_USER_DATA',
    payload: data
  };
};

export const clearUserData = (data) => {
  return {
    type: 'CLEAR_USER_DATA',
  };
};
