import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import useSidebar from './useSidebar';

const SidebarBody = () => {
  const dispatch = useDispatch();
  const activeLocation = useSelector(({ user }) => user.activeLocation);
  const companyLogoUrl = activeLocation && activeLocation.company && activeLocation.company.logo_url;

  const {clickClose} = useSidebar.useActions(dispatch);

  return (
    <Row className='sidebar-body py-1 px-3'>
      <Col xs={12}>        
        <h4 className='fb-heading-title'>SETTINGS</h4>
        <ul className='main-navigation'>
          
          <li><NavLink to='/templates' activeClassName='active' className='order-guide-menu' onClick={clickClose}>Order Guide Templates</NavLink></li>
          
          <li><NavLink to='/logout' className='log-out-menu'>Log Out</NavLink></li>
        </ul>        
      </Col>      
    </Row>

  );
};

export default SidebarBody;
