
import React, {useState, useEffect} from 'react';
import { isMobile } from 'react-device-detect';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { Row, Col, Button } from 'reactstrap';
import Header from '../../../../../SharedComponents/Header/Header';
import templateApi from '../../../../../api/templateApi';
import AlertModal from '../../../../../SharedComponents/Modals/AlertModal/AlertModal';
import { useDispatch } from "react-redux";
import { showModal } from "../../../../../reducers/modals/modalsActions";

const TemplateHeader = (props) => {

    const dispatch = useDispatch();
    const [downloadLink, setDownloadLink] = useState('');

    const generateObject = () => {
        if (downloadLink !== '') {
          let link = document.createElement('a');
          link.href = downloadLink;
          link.download = '';
          link.dispatchEvent(new MouseEvent('click'));
        }
      };

    const userReport = () => {
        templateApi.userReport()
        .then((res) => {
            const file = res.data.data;
            setDownloadLink(file);      
        })
        .catch(error => {
            const newProps = {
                title: 'Report Failed',
                message: 'There was an error while querying users report',
                btnLabel: 'Ok',
                btnSize: 'lg',
                color: 'green'
            };
            dispatch(showModal(AlertModal, newProps));
        });
    };

    useEffect(() => {
        generateObject();
      }, [downloadLink]);
  

    return (
        <Header showMenu={true} showLogo={isMobile ? false : true} showBack={false} title='Order Guide Templates' showCart={false} >
            <ShowIf show={!isMobile}>
                <Row className='header h-inherit py-3 align-items-center m-0 text-gray'>
                    <Col xs={2}> Template Name</Col>
                    <Col xs={3}> Distributor </Col>
                    <Col xs={1}> Date </Col>
                    <Col xs={1}> Expires After </Col>
                    <Col xs={1}> Status </Col>                    
                    <Col xs={1}> Options </Col>
                    <Col xs={1}> <Button color='green' onClick={userReport}>User Report</Button> </Col>
                </Row>
            </ShowIf>            
        </Header>
    )
}

export default TemplateHeader;