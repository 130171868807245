import React, {useState} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Input } from 'reactstrap';
import useModal from '../../../../../SharedComponents/Modals/useModal';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { MdClose, MdCloudUpload, MdDelete } from 'react-icons/md';
import { colors, emailTypeOptions } from "../../../../../config/constants";
import '../Template.scss';
import * as $ from 'jquery';
import excelIcon from '../../../../../assets/images/excel-icon.jpg';

const EmailPreviewModal = (props) => {
  const { id, leftButton, leftClick, rightButton, rightClick, btnSize, footerClass, title, user, isDsr, distributor, assignedMerchants, lastUpload, emailText, emailType, lastLoggedCustomers } = props;
  const modalprops = useModal(id);
  const showTitle = title ? true : false;

  const [valueEmail, setValueEmail] = useState(emailText);
  const [file, setFile] = useState(null);
  const [showSelectedFile, setShowSelectedFile] = useState(false);

  const clickLeft = () => {
    if (leftClick) {
        leftClick();
    };
    modalprops.toggle();
  };

  const clickRight = () => {
    if (rightClick) {
        let to = [];
        to.push(user.email);
        lastLoggedCustomers.map((u,i) => { to.push(u.email)} );
        // console.log({to, valueEmail, emailType});
        rightClick(to, valueEmail, emailType, file);
    };
    modalprops.toggle();
  };

  const openFileDialog = () => {
    $('#file-upload').click();
  };

  const selectFile = () => {
    const newFile = $('#file-upload').prop('files')[0];
    if(newFile){
        setFile(newFile);
        setShowSelectedFile(true);
    }else{
        setShowSelectedFile(false);
    }
  };

  const deleteFile = () => {
    setFile(null);
    setShowSelectedFile(false);     
  };


  return (
    <Modal {...modalprops} backdrop='static' keyboard={false}>
      <ShowIf show={showTitle}>
        <ModalHeader className='text-center'>
            <Row className='m-0'>
                <Col className='col-11 d-flex justify-content-center px-1 clickable'>
                    {title}
                </Col>
                <Col className='w-fc d-flex justify-content-end px-1 clickable' onClick={() => {modalprops.toggle()}}>
                    <MdClose className='clickable' size={20} color={colors.lightBlueText} />
                </Col>
            </Row>
        </ModalHeader>        
      </ShowIf>
      <ModalBody className='text-center'>
            <Row className='m-0'>
                <Col className='col-4 p-1 text-gray text-right'>
                    To: {isDsr ? 'Dsr' : 'Customer'}
                </Col>
                <Col className='w-fc p-1 text-left'>
                    {user.email}
                </Col>
            </Row>
            <Row className='m-0'>
                <Col className='col-4 p-1 text-gray text-right'>
                    To Customer:
                </Col>
                <Col className='w-fc p-1 text-left'>
                    {lastLoggedCustomers.map((u,i) => <Row key={`last_logged_${i}`}><Col> {u.email} </Col></Row> )}
                </Col>
            </Row>
            <Row className='m-0'>
                <Col className='col p-1 text-gray text-right'>
                    <Input type="textarea" id="emailText" value={valueEmail} onChange={e => setValueEmail(e.target.value)} rows={15} />
                </Col>
            </Row>
            <Row className='m-0'>
                <Col className='col p-1 text-gray text-left'>
                    Attach File:
                </Col>
            </Row>
            <ShowIf show={!showSelectedFile}>
              <Row className='m-0' onClick={openFileDialog}>
                  <Col className='col p-1 text-gray text-center upload-file-outline'>
                      <MdCloudUpload  color={colors.lightBlueText} style={{fontSize:'25px'}}/><br/>
                      Click to Upload
                  </Col>
                  <input className='d-none' onChange={() => selectFile()} type='file' id='file-upload' accept=".xls,.xlsx,.csv"/>
              </Row>
            </ShowIf>
            <ShowIf show={showSelectedFile}>
              <Row className='py-2'>
                  <Col className='text-left pl-4'>
                      <img src={excelIcon} style={{widh: '2em', height: '2em', paddingRight: '1em'}} />
                      {file ? file.name : ''}
                  </Col>
                  <Col className='w-fc text-left pr-4'>
                      <MdDelete  color={colors.lightBlueText} onClick={() => deleteFile()} style={{fontSize:'20px'}}/>
                  </Col>
              </Row>
            </ShowIf>
      </ModalBody>
      <ModalFooter className={footerClass}>
        <Row className="text-center w-100">
          <Col><Button color={leftButton.color} size={btnSize} onClick={clickLeft}>{leftButton.title}</Button></Col>
          <Col><Button color={rightButton.color} size={btnSize} onClick={clickRight}>{rightButton.title}</Button></Col>            
        </Row>

      </ModalFooter>
    </Modal>
  );
};

EmailPreviewModal.defaultProps = {
  footerClass: '',
  btnSize: 'sm',
};

export default EmailPreviewModal;
