
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import TemplateList from "./TemplateList/TemplateList";
import TemplateDetail from "./TemplateDetail/TemplateDetail";
import TemplateSettings from "./TemplateSettings/TemplateSettings";
import TemplateMatches from "./TemplateMatches/TemplateMatches";


const Template = (props) => {
    
    return (
        <Switch>        
            <Route exact path='/templates'>
                <Container fluid={true} className={isMobile ? 'px-0' : ''}>
                    <TemplateList/>
                </Container>
            </Route>
            <Route exact path={`/templates/:id/detail`}>
                <Container fluid={true} className={isMobile ? 'px-0' : ''}>
                    <TemplateDetail/>
                </Container>
            </Route>
            <Route exact path={`/templates/:id/settings`}>
                <Container fluid={true} className={isMobile ? 'px-0' : ''}>
                    <TemplateSettings/>
                </Container>
            </Route>
            <Route exact path={`/templates/:id/matches`}>
                <Container fluid={true} className={isMobile ? 'px-0' : ''}>
                    <TemplateMatches/>
                </Container>
            </Route>
            {/* <Route>
                <Redirect to='/templates' />
            </Route> */}
        </Switch>
        
    )
}

export default Template;