import React from 'react';
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import templateApi from '../../../../api/templateApi';
import { showModal } from "../../../../reducers/modals/modalsActions";
import { useSelector, useDispatch } from "react-redux";
import TemplateMatchTestModal from '../Template/TemplateMatches/TemplateMatchTestModal';
import AlertModal from '../../../../SharedComponents/Modals/AlertModal/AlertModal';


const useApi = (props) => {
  const [templateList, setTemplateList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(2);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);

  const getTemplates = () => {
    if(page>pageCount){ return;}
    const params = {
      page : page,
      limit : 20
    };
    setIsLoading(true);
    templateApi.getAllTemplates(params).then((res) => {
      const temp = res.data.data;
      setTemplateList(templateList.concat(temp.data));
      setPage(page+1);
      setPageCount(temp._meta.pageCount);
      setLoadMore(temp._meta.pageCount > temp._meta.currentPage);
      setIsLoading(false);
    })
    .catch(error => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getTemplates();
  }, []);


  return { templateList, setTemplateList, getTemplates, page, isLoading, loadMore  };
}

const useDetailApi = (id) => {
  const [data, setData] = useState([]);

  const getTemplate = () => {
    templateApi.getTemplate(id).then((res) => {
      setData(res.data.data);
    });
  };

  useEffect(() => {
    getTemplate();
  }, []);


  return { data, getTemplate };
}

const useRow = (props) => {

  const {data, templateList, setTemplateList} = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [hover, setHover] = useState(false);
  
  const openSettings = (data) => {    
    history.push({pathname: `/templates/${data.id}/settings`, state: {file:data}});
  };  

  const viewTemplate = (id) => {
    history.push(`/templates/${id}/detail`);
  };

  const matchingTemplates = (id) => {
    history.push(`/templates/${id}/matches`);
  };

  const downloadTemplate = (fileUrl) => {
    const excelElement = document.createElement('a');
    excelElement.href = fileUrl;
    excelElement.click();
  }

  const deleteTemplate = (id) => {
    templateApi.deleteTemplate(id).then((res) => {
        const test = res.data.data;
        setTemplateList(templateList.filter(temp => (temp.id != id)));
    })
    .catch(error => {
    
    });
  }


  return {
    setHover, hover, openSettings, viewTemplate, downloadTemplate, deleteTemplate, matchingTemplates
  }

}

const useMatchesApi = (id) => {
  const [matchList, setMatchList] = useState([]);

  const getMatches = () => {
    templateApi.checkTemplateMatches(id).then((res) => {            
        const matches = res.data.data;
        setMatchList(matches);
    })
    .catch(error => {
        
    });
  };

  useEffect(() => {
    getMatches();
  }, []);


  return { matchList, setMatchList, getMatches };
}

const useMatchesRow = (props) => {

  const {data, tempId} = props;
  const dispatch = useDispatch();
  const history = useHistory();
  
  const testTemplate = () => {
    const params = {
      match : data.id
    }
    templateApi.test(tempId, params).then((res) => {
        const test = res.data.data;
        const newProps = {
          menu: isMobile ? true : false,
          fullMenu: true,
          data: data,
          template: tempId,
          title: 'Test Results for Matching Template ('+ data.id+')',
          test: test,
          color: 'green'
      };
      dispatch(showModal(TemplateMatchTestModal, newProps));
    })
    .catch(error => {
    
    });
  }

  return {
    testTemplate
  }

}

export default {
  useApi, useDetailApi, useRow, useMatchesApi, useMatchesRow
};