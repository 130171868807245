import React from 'react';
import { Card, Row, Col, Button } from "reactstrap";
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import Moment from 'react-moment';
import { isMobile } from 'react-device-detect';
import '../Template.scss';
import numeral from 'numeral';

const TestInvalidRow = (props) => {
    const { data, style } = props;
    
    return (
       <Card className='p-2'>
           <ShowIf show={!isMobile}>
                <Row style={{fontSize: style}}>
                    <Col xs={1}> {data.din} </Col>
                    <Col xs={1} className='px-0 text-capitalize text-truncate'> {data.type}</Col>
                    <Col xs={4} className='px-0 text-capitalize text-truncate'> {data.description}</Col>
                    <Col xs={2}> {data.value}</Col>
                    <Col xs={1}> {data.row}</Col>
                </Row>
            </ShowIf>
            
       </Card>
    )

}

TestInvalidRow.defaultProps = {
    style: '14px',
};

export default TestInvalidRow;